<template>
  <v-ace-editor
    v-model:value="modelCode"
    lang="csharp"
    theme="monokai"
    @init="editorInit"
    :options="options"
    style="height: 230px"
  />
</template>
<script>
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/ext-language_tools";
//import beautifier from 'ace-builds/src-noconflict/ext-beautify'

import "ace-builds/src-noconflict/snippets/csharp";
import "ace-builds/src-noconflict/ext-searchbox";

// import ace from "ace-builds";
// import workerJsUrl from "file-loader?esModule=false!ace-builds/src-noconflict/worker-javascript.js";
// ace.config.setModuleUrl("ace/mode/javascript_worker", workerJsUrl);

export default {
  name: "SetCodeEditor",
  props: ["value"],
  components: {
    VAceEditor,
  },
  watch: {
    value(newValue) {
      this.modelCode = newValue;
      this.$emit("setCodeEmiting", this.modelCode);
    },
    modelCode(newValue) {
      this.$emit("setCodeEmiting", newValue);
    },
  },
  data() {
    return {
      modelCode: "",
      editorInit: function (editor) {
        editor.setOptions({
          enableBasicAutocompletion: true,
          enableSnippets: true,
          enableLiveAutocompletion: true,
          autoScrollEditorIntoView: true,
        });
        editor.setShowPrintMargin(false);
      },
      options: {
        autoScrollEditorIntoView: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        useWorker: true,
        wrap: true,
      },
    };
  },
};
</script>
