<template>
  <form class="row form-modal-edit-model" novalidate>
    <div class="col-md-12">
      <div class="card mb-0 mt-0 mb-0">
        <div class="card-body pt-2 pb-2">
          <div class="row">
            <div class="col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "SetCodeModels.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                v-model="setModelData.name"
                @keyup="formulaNameKeyup()"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="FormulaName" class="form-label required">{{
                $t(
                  "SetCodeModels.FormulaName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control text-uppercase"
                id="FormulaName"
                v-model="setModelData.formulaName"
                @keyup="formulaNameKeyup()"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
                :disabled="!$parent.isLocalObject"
              />
            </div>
          </div>
          <div class="col-md-12 mt-2">
            <label for="Description" class="form-label required"
              >{{
                $t(
                  "SetCodeModels.Description",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <textarea
              class="form-control"
              rows="1"
              id="Description"
              v-model="setModelData.description"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
              required
            ></textarea>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card mt-3 mb-0">
                <div class="card-body">
                  <h3 class="card-title">
                    <i class="bi bi-sliders"></i>
                    {{
                      $t(
                        "SetCodeModels.ModelCode",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </h3>
                  <div class="d-flex align-items-start mt-3">
                    <div
                      class="nav flex-column nav-pills me-3"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <button
                        class="nav-link active"
                        id="v-pills-save-as-code-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-save-as-code-content"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-save-as-code-tab"
                        aria-selected="true"
                      >
                        {{
                          $t(
                            "SetModel.SaveAsCode",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </button>
                    </div>
                    <div class="tab-content w-100" id="v-pills-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="v-pills-save-as-code-content"
                        role="tabpanel"
                        aria-labelledby="v-pills-save-as-code-tab"
                      >
                        <SetCodeEditor
                          :value="setModelData.modelCode"
                          @setCodeEmiting="getSetCode"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import SetCodeEditor from "../editor/SetCodeEditor.vue";
export default {
  name: "SetModelEdit",
  components: {
    SetCodeEditor,
  },
  data() {
    return {
      setModelData: {},
    };
  },
  methods: {
    getSetCode(modelCode) {
      this.setModelData.modelCode = modelCode;
      this.setModelData.isActive = true;
    },
    formulaNameKeyup: function () {
      if (!this.$parent.isLocalObject) return;

      if (String.isNullOrWhiteSpace(this.setModelData.name)) {
        this.setModelData.formulaName = "";
        return;
      }

      this.setModelData.formulaName = String.replaceAlphanumeric(
        this.setModelData.name,
        "_"
      ).toUpperCase();
    },
  },
};
</script>
